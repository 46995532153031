import axios from 'axios'

import store from '../../redux/store'
import { loginUser, logoutUser } from '../../../features/global'
// import store from '../../redux/store'
import { loadLocalStorage } from '../../utils'

import { PUBLIC_INSTANCE_CONFIG, PRIVATE_INSTANCE_CONFIG } from '../constants'

const publicInstance = axios.create(PUBLIC_INSTANCE_CONFIG)

const privateInstance = axios.create(PRIVATE_INSTANCE_CONFIG)
privateInstance.interceptors.request.use((request) => {
  const userState = loadLocalStorage('apins-auth')

  if (userState?.token) {
    request.headers.Authorization = 'Bearer ' + userState.token
  }
  return request
})

// const configureCacheControlInterceptor = (axiosInstance) => {
//   axiosInstance.interceptors.request.use((config) => {
//     config.headers['Cache-Control'] = 'no-cache, must-revalidate'
//     return config
//   })
// }

// configureCacheControlInterceptor(publicInstance)
// configureCacheControlInterceptor(privateInstance)

const URL_REFRESH_TOKEN = '/login/refresh_token'

let refreshTokenPool = { requestCount: 0 }
privateInstance.interceptors.response.use(
  (response) => {
    return response
  },
  async (error) => {
    if (error?.response) {
      const { status, config } = error?.response
      const userState = loadLocalStorage('apins-auth')
      if (config.url === URL_REFRESH_TOKEN || status !== 401 || error.config.retry) {
        throw error
      }
      try {
        if (!refreshTokenPool.promise) {
          const payload = { refresh_token: userState.refresh_token }
          refreshTokenPool.promise = privateInstance
            .post(URL_REFRESH_TOKEN, payload)
            .then((response) => {
              const payload = { ...userState, ...response.data }
              store.dispatch(loginUser(payload))
              return response
            })
            .catch(() => {
              throw new Error('token_refresh_failure')
            })
          refreshTokenPool.requestCount = 1
        } else {
          refreshTokenPool.requestCount++
        }
        const response = await refreshTokenPool.promise
        refreshTokenPool.requestCount--
        if (refreshTokenPool.requestCount === 0) {
          refreshTokenPool.promise = null
        }
        const newRequestConfig = {
          ...config,
          retry: true,
          headers: { ...config.headers, Authorization: 'Bearer ' + response.data.token },
        }
        return publicInstance(newRequestConfig)
      } catch {
        console.debug('Error fetching new access token')
        store.dispatch(logoutUser())
        setTimeout(() => window.location.reload(), 1000)
        throw new Error('token_refresh_failure')
      }
    }
  }
)

export const instances = { publicInstance, privateInstance }
